<template>
  <div class="event-status-label text-nowrap" :class="{ 'event-status-label_time': status !== 'true'}">
    <span
      v-t="status === 'true' ? { path: 'EVENT_STATUS.DEFAULT' } : date"
      class="d-block text-truncate"
    />
  </div>
</template>

<script>
  export default {
    name: 'EventStatusLabel',
    props: {
      status: {
        type: String,
        default: '',
        required: true,
      },
    },
    computed: {
      date() {
        const months = [
          "January", "February", "March",
          "April", "May", "June",
          "July", "August", "September",
          "October", "November", "December",
        ];
        const shortDate = this.status?.split(' ')[0];
        const dateParts = shortDate?.split('-');
        const month = dateParts[1][0] === '0' ? dateParts[1][1] : dateParts[1];

        return `${dateParts[2]} ${months[month - 1]}, ${dateParts[0]}`;
      },
    },
  };
</script>

<style lang="scss">
  .event-status-label {
    background-color: $subscribe-bg;
    border-radius: $subscribe-border-radius;
    color: $subscribe-color;
    font-weight: 500;
    margin: 10px;
    min-width: 0;
    padding: $subscribe-padding;

    &_time {
      background: #3434347a;
    }
  }
</style>
