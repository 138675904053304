<template>
  <div
    class="product-status-label text-nowrap"
  >
    <span class="d-block text-truncate">Discount</span>
  </div>
</template>

<script>
  export default {
    name: 'ProductStatusLabel',
  };
</script>

<style lang="scss">
.product-status-label {
  background-color: $product-discount;
  border-radius: $subscribe-border-radius;
  color: $subscribe-color;
  font-weight: 500;
  margin: 10px;
  min-width: 0;
  padding: $subscribe-padding;
}
</style>
