<template>
  <img class="creator-logo" :src="logoImage">
</template>

<script>
  export default {
    name: 'CreatorLogo',
    props: {
      logoImage: {
        type: String,
        required: true,
      },
    },
  };
</script>

<style lang="scss">
  .creator-logo {
    width: 30px;
    height: 30px;
    margin: 10px;
    border-radius: 50%;
  }

</style>
